/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import FacebookLogin from 'react-facebook-login';
import {
  get, isEmpty, map, merge, pick, replace,
} from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  Cancel, Close, Visibility, VisibilityOff,
} from '@material-ui/icons';
import queryString from 'query-string';
import CTAButton from './CTAButton';
import AppleIcon from '../../Assets/Images/apple.svg';
import FacebookIcon from '../../Assets/Images/facebook_rounded.svg';
import ErrorModal from './ErrorModal';
import { defaultErrorMessage, blankHref, generalCheckPassRules } from '../../constants';
import AppleLogin from './AppleLogin';
import { HOST_ROOT } from '../../apiConfig';
import {
  logInComplete, logInStart, sePhoneVerificationComplete,
  sePhoneVerificationInitiate, sePhoneVerificationSkip, seResetPasswordEmailSent,
  seResetPasswordInitiate, setAllUserIds, signUpAccountDetails, signUpComplete, signUpStart,
} from './WebAnalytics';
import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import checkMark from '../../Assets/Images/Check_mark.png';
import { loadClient, setField } from '../../Actions';
import {
  fbSignin, fbSignup, getClient, getEmail, getPhoneNumber, getSessionParams,
  gglSignin, gglSignup, triggerResetPasswordEmail, updateClientById,
  userSignin, userSignup,
} from './Helpers';
import GoogleSignin from './GoogleSignin';
import NavbarRedesignForMarketplace from './NavbarRedesignForMarketplace';
import ResetPasswordPopups from './ResetPasswordPopups';

class GenericAuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      mobileNumber: '',
      signin: props.isSignin,
      isValidNumber: false,
      countryCode: '',
      canEmail: false,
      marketingCallsTexts: false,
      showErrorModal: false,
      error: null,
      facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '679911028787197',
      width: window.innerWidth,
      isValidEmail: false,
      cart_id: get(props, 'cart_id', null),
      emailExists: false,
      showPass: false,
      needToFinishSignup: false,
      phoneAlreadyExists: false,
      listOfPassMistakes: [],
      showEmailSent: false,
      userLockedOut: false,
      resetToken: '',
    };

    this.otherLoginOptions = this.otherLoginOptions.bind(this);
    this.orSeparator = this.orSeparator.bind(this);
    this.loginForm = this.loginForm.bind(this);
    this.signupForm = this.signupForm.bind(this);

    this.signup = this.signup.bind(this);
    this.signin = this.signin.bind(this);
    this.renderSignupOrSignin = this.renderSignupOrSignin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.facebookSignup = this.facebookSignup.bind(this);
    this.facebookSignin = this.facebookSignin.bind(this);
    this.renderErrorModal = this.renderErrorModal.bind(this);
    this.phoneCommunicationOptin = this.phoneCommunicationOptin.bind(this);
    this.marketingEmailOptin = this.marketingEmailOptin.bind(this);
    this.formSwitcher = this.formSwitcher.bind(this);
    this.fetchClientOnLoad = this.fetchClientOnLoad.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.sendPixelData = this.sendPixelData.bind(this);
    this.resetPasswordLink = this.resetPasswordLink.bind(this);
    this.emailCheck = this.emailCheck.bind(this);
    this.checkForExistenceNCallback = this.checkForExistenceNCallback.bind(this);
    this.googleSignin = this.googleSignin.bind(this);
    this.googleSignup = this.googleSignup.bind(this);
    this.finishSignupForm = this.finishSignupForm.bind(this);
    this.finishSSOSignup = this.finishSSOSignup.bind(this);
    this.checkPhoneExistence = this.checkPhoneExistence.bind(this);
    this.showPersona = this.showPersona.bind(this);
    this.checkPassRules = this.checkPassRules.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
    const parsedParams = queryString.parse(window.location.search);
    const resetToken = get(parsedParams, 'reset_password_token', '');
    this.setState({ resetToken });
  }

  componentWillUnmount() {
    if (this.props.removePersona) {
      this.props.removePersona();
    }
  }

  showPersona(onlyToInitialize = false) {
    if (this.props.showPersonaInParent) {
      this.props.showPersonaInParent(
        () => sePhoneVerificationInitiate(this.state.signin && this.state.emailExists
          ? 'log_in' : 'sign_up', 'user_auth_variant'),
        () => sePhoneVerificationComplete(this.state.signin && this.state.emailExists
          ? 'log_in' : 'sign_up', 'user_auth_variant'),
        () => sePhoneVerificationSkip(this.state.signin && this.state.emailExists
          ? 'log_in' : 'sign_up', 'user_auth_variant'),
        this.props.next,
        onlyToInitialize,
      );
    } else if (!onlyToInitialize) {
      this.props.next();
    }
  }

  nextStep(appleUserId = null, appleUserEmail = null) {
    this.fetchClientOnLoad(appleUserId, appleUserEmail);
  }

  fetchClientOnLoad(appleUserId = null, appleUserEmail = null) {
    getClient((response) => {
      const { client } = response.data;
      if (!sessionStorage.getItem('userSet')) {
        setAllUserIds(client.user_id, client.email);
      }

      this.props.loadClient({ ...client, loggedIn: true });
      this.showPersona(true);
      const phoneVerificationRequired = get(this.props, 'client.phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
      const phoneNumber = get(this.props, 'client.phone', '');
      if (appleUserId && !appleUserEmail) {
        logInComplete('Apple', appleUserId, phoneVerificationRequired ? 'user_auth_variant' : '');
      } else if (appleUserId && appleUserEmail) {
        signUpComplete(appleUserId, 'Apple', appleUserEmail, phoneVerificationRequired ? 'user_auth_variant' : '');
      }
      getSessionParams(client.user_id, (response2) => {
        const { session_id } = response2.data;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user_session_info',
          user_id: client.user_id,
          session_id,
        });
      });
      if (phoneVerificationRequired) {
        this.setState({
          needToFinishSignup: true, firstName: get(this.props, 'client.first_name', ''), lastName: get(this.props, 'client.last_name', ''), mobileNumber: phoneNumber ? `+${replace(phoneNumber, /\D/g, '')}` : '', isValidNumber: Boolean(phoneNumber),
        }, this.checkPhoneExistence);
      } else if (this.props.next) {
        this.props.next();
      }
    }, (e) => {
      const { response } = e;
      if (response.status === 401) {
        this.props.loadClient({ loggedIn: false });
      }

      if (response.status === 404 && get(response, 'data.errors.0.code', '') === 'client_not_found' && response.data.result === false) {
        window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      }
      if (response.status === 200) {
        this.props.loadClient({ loggedIn: false });
      }
    });
  }

  emailCheck() {
    const { isValidEmail, email } = this.state;
    if (isValidEmail) {
      getEmail(email, (resp) => {
        if (get(resp, 'data.admin', false)) {
          this.setState({
            emailExists: false, signin: true, showErrorModal: true, error: "Can't login with this user type",
          });
        } else if (get(resp, 'data.result', false)) {
          this.setState({ emailExists: true, signin: true });
        } else {
          this.setState({ emailExists: false, signin: false });
        }
      }, () => {
        this.setState({ emailExists: false, signin: false });
      });
    }
  }

  checkForExistenceNCallback(profileObject, signinCb, signupCb) {
    getEmail(get(profileObject, 'email', ''), (resp) => {
      if (get(resp, 'data.admin', false)) {
        this.setState({ emailExists: false, showErrorModal: true, error: "Can't login with this user type" });
      } else if (get(resp, 'data.result', false)) {
        this.setState({ emailExists: true });
        signinCb(profileObject);
      } else {
        this.setState({ emailExists: false });
        signupCb(profileObject);
      }
    }, () => {
      this.setState({ emailExists: false });
      signupCb(profileObject);
    });
  }

  googleSignin(profileObject) {
    const {
      email, family_name, given_name, id_token,
    } = profileObject;
    const params = {
      first_name: given_name, last_name: family_name, email, oauth_token: id_token, provider: 'google',
    };

    gglSignin(params, (resp) => {
      const phoneVerificationRequired = get(resp, 'data.phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
      logInStart('React App');
      logInComplete('Google', get(resp, 'data.user_id', ''), phoneVerificationRequired ? 'user_auth_variant' : '');
      setTimeout(this.nextStep, 2000);
    }, (error) => {
      const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  googleSignup(profileObject) {
    // email, family_name, given_name, id, locale, picture, verified_email
    const {
      email, family_name, given_name, id_token,
    } = profileObject;
    const params = {
      first_name: given_name, last_name: family_name, email, oauth_token: id_token, provider: 'google',
    };

    gglSignup(
      merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      (resp) => {
        const {
          email, user_id, first_name, last_name, mobile_number,
        } = get(resp, 'data', {});
        const phoneVerificationRequired = get(resp, 'data.phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
        signUpStart();
        signUpComplete(user_id, 'Google', email, phoneVerificationRequired ? 'user_auth_variant' : '');
        signUpAccountDetails(email, first_name, last_name, mobile_number);
        this.sendPixelData(user_id, 'Google', email);
        setTimeout(this.nextStep, 2000);
      },
      (error) => {
        const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      },
    );
  }

  formSwitcher() {
    return (
      <div className="size-16-24 contentPrimary mb-32">
        {this.state.signin ? (
          <>
            Don't have an account?
            {' '}
            <a className="CactusFlowerPrimary font-weight-bold" href={blankHref} onClick={(event) => { event.preventDefault(); this.setState({ signin: false }); }}>Signup</a>
          </>
        ) : (
          <>
            Already have an account?
            {' '}
            <a className="CactusFlowerPrimary font-weight-bold" href={blankHref} onClick={(event) => { event.preventDefault(); this.setState({ signin: true }); }}>Login</a>
          </>
        )}
      </div>
    );
  }

  checkPhoneExistence() {
    const { mobileNumber, isValidNumber, isSignin } = this.state;
    if (mobileNumber && isValidNumber && !isSignin) {
      getPhoneNumber(mobileNumber, (resp) => {
        if (get(resp, 'data.result', false)) {
          this.setState({ phoneAlreadyExists: `+${replace(get(this.props, 'client.phone', ''), /\D/g, '')}` !== mobileNumber });
        } else {
          this.setState({ phoneAlreadyExists: false });
        }
      }, () => {
        this.setState({ phoneAlreadyExists: false });
      });
    }
  }

  handleInputChange({ isValidNumber, number, countryCode }) {
    this.setState({
      isValidNumber, mobileNumber: isValidNumber ? `+${replace(number, /\D/g, '')}` : number, countryCode, phoneAlreadyExists: false,
    }, this.checkPhoneExistence);
  }

  phoneCommunicationOptin() {
    if (this.state.countryCode === 'us') {
      return (
        <div className="form-group mb-24 contentTertiary">
          <div className="d-flex-only justify-content-spaced">
            <div className="mr-12 centered">
              <input type="checkbox" checked={this.state.marketingCallsTexts} className="" onChange={() => this.setState({ marketingCallsTexts: !this.state.marketingCallsTexts })} />
            </div>
            <div className="size-10-15 contentTertiary">
              I authorize Soothe to deliver marketing messages using automated SMS messaging.
              Message and data rates may apply. Message frequency varies.
            </div>
          </div>
        </div>
      );
    }
  }

  marketingEmailOptin() {
    if (this.state.countryCode !== 'us' && this.state.countryCode !== '') {
      return (
        <div className="form-group mb-24 contentTertiary">
          <div className="d-flex-only justify-content-spaced">
            <div className="mr-12">
              <input type="checkbox" checked={this.state.canEmail} className="" onChange={() => this.setState({ canEmail: !this.state.canEmail })} />
            </div>
            <div className="optin-text">
              I would like to receive promotional emails from Soothe
            </div>
          </div>
        </div>
      );
    }
  }

  sendPixelData(userId, type, userEmail) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') {
      return;
    }
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client_signup',
        user_id: userId,
        user_account: userEmail,
        type,
      });
    } catch (e) {
      console.log({ e });
    }
  }

  signup(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (!this.state.mobileNumber || !this.state.isValidNumber || this.state.phoneAlreadyExists) {
      this.setState({ showErrorModal: true, error: 'Please provide a valid phone number' });
      return;
    }
    const { firstName } = this.state;
    const { lastName } = this.state;

    const params = merge(
      { mobile_number: this.state.mobileNumber, first_name: firstName, last_name: lastName },
      pick(this.state, ['name', 'email', 'password', 'cart_id']),
    );

    userSignup(
      { 'X-CSRF-Token': get(this.props, 'fieldsHolder.csrfToken', ''), withCredentials: true },
      merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      (resp) => {
        const { user } = resp.data;
        const phoneVerificationRequired = get(user, 'phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
        this.props.setField('csrfToken', get(resp, 'data.csrfToken', ''));
        const {
          email, id, first_name, last_name, mobile_number,
        } = user || {};
        setAllUserIds('id', email ? email.toString() : '');
        signUpStart();
        signUpComplete(id, 'email', email ? email.toString() : '', phoneVerificationRequired ? 'user_auth_variant' : '');
        signUpAccountDetails(email, first_name, last_name, mobile_number);
        this.sendPixelData(id, 'email', email ? email.toString() : '');
        setTimeout(this.nextStep, 2000);
      },
      (error) => {
        const user_already_exists = get(error, 'response.data.errors.user_already_exists', false);
        if (user_already_exists) {
          window.scrollTo(0, 0);
          this.setState({ signin: true });
        } else {
          const message = get(error, 'response.data.errors.email.0', '') || get(error, 'response.data.errors.mobile_number.0', '') || get(error, 'response.data.errors.password.0', '') || get(error, 'response.data.errors.first_name.0', '') || get(error, 'response.data.errors.last_name.0', '') || get(error, 'response.data.errors.0.message', defaultErrorMessage);
          this.setState({ error: message, showErrorModal: true });
        }
      },
    );
  }

  signin() {
    const params = pick(this.state, [
      'email', 'password', 'cart_id',
    ]);
    userSignin({ 'X-CSRF-Token': get(this.props, 'fieldsHolder.csrfToken', ''), withCredentials: true }, params, (resp) => {
      const {
        success, result, user, type,
      } = resp.data;

      if (result || type === 'client') {
        const phoneVerificationRequired = get(user, 'phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
        const { email, id } = user || {};
        this.props.setField('csrfToken', get(resp, 'data.csrfToken', ''));
        setAllUserIds(id, email ? email.toString() : '');
        logInStart('React App');
        logInComplete('email', id, phoneVerificationRequired ? 'user_auth_variant' : '');
        setTimeout(this.nextStep, 2000);
      } else if (success && type === 'therapist') {
        return window.location.replace(`${HOST_ROOT}/therapists/${get(user, 'id', '')}`);
      } else if (success && type === 'b2b_client' && !!get(user, 'b2b_admin_company_id', '')) {
        return window.location.replace(`${HOST_ROOT}/sootheatwork/admin/appointments`);
      } else if (success && result == null) {
        return window.location.replace(`${HOST_ROOT}/clients/${get(user, 'id', '')}`);
      }
    }, (error) => {
      const message = get(error, 'response.data.message') || get(error, 'response.data.errors.0.message', defaultErrorMessage);
      if (get(error, 'response.data.errors.0.code', '') === 'account_locked_too_many_attempts') {
        this.setState({ userLockedOut: true });
      } else {
        this.setState({ error: message, showErrorModal: true });
      }
    });
  }

  facebookSignup(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    fbSignup(
      merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      (resp) => {
        const {
          email, user_id, first_name, last_name, mobile_number,
        } = get(resp, 'data', {});
        const phoneVerificationRequired = get(resp, 'data.phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
        signUpStart();
        signUpComplete(user_id, 'Facebook', email, phoneVerificationRequired ? 'user_auth_variant' : '');
        signUpAccountDetails(email, first_name, last_name, mobile_number);
        this.sendPixelData(user_id, 'Facebook', email);
        setTimeout(this.nextStep, 2000);
      },
      (error) => {
        const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      },
    );
  }

  facebookSignin(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    fbSignin(params, (resp) => {
      const phoneVerificationRequired = get(resp, 'data.phone_verification_required', false) && get(this.props, 'client.ab_tests.mobile_phone_verification', '') === 'needs_verification';
      logInStart('React App');
      logInComplete('Facebook', get(resp, 'data.user_id', ''), phoneVerificationRequired ? 'user_auth_variant' : '');
      setTimeout(this.nextStep, 2000);
    }, (error) => {
      const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  finishSSOSignup(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const {
      firstName, lastName, mobileNumber, marketingCallsTexts, canEmail,
    } = this.state;
    updateClientById(get(this.props, 'client.user_id', ''), {
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      gdpr_marketing_permissions: canEmail,
      marketing_calls_texts: marketingCallsTexts,
    }, (resp) => {
      const client = get(resp, 'data.client', {});
      this.props.loadClient({ ...client, loggedIn: true });
      if (get(client, 'phone_verification_required', false) && get(client, 'ab_tests.mobile_phone_verification', '') === 'needs_verification') {
        this.showPersona();
      } else if (this.props.next) {
        this.props.next();
      }
    });
  }

  renderSignupOrSignin() {
    if (this.state.needToFinishSignup) {
      return this.finishSignupForm();
    }
    if (this.state.signin) {
      return this.loginForm();
    }
    return this.signupForm();
  }

  otherLoginOptions() {
    return (
      <div className="">
        <AppleLogin
          backToSelectServiceCallback={this.nextStep}
          setEmailExists={() => { this.setState({ emailExists: true }); }}
          inPopup
          customLogo={AppleIcon}
          customCopy="Continue with Apple"
          wrapperClassName="br-rd-24 br-opaque-2 ptb-14 centered mb-16"
          customClassName="size-16-20 DarkBluePrimary font-weight-bold"
          iconClassName="w-h-20 mr-8"
          sendPixelData={this.sendPixelData}
          showError={(message) => {
            this.setState({ showErrorModal: true, error: message || defaultErrorMessage });
          }}
        />

        <FacebookLogin
          appId={this.state.facebookAppId}
          autoLoad={false}
          fields="name,email"
          scope="public_profile,email"
          callback={(profileObject) => this.checkForExistenceNCallback(
            profileObject,
            this.facebookSignin,
            this.facebookSignup,
          )}
          textButton={(
            <div className="br-rd-24 br-opaque-2 ptb-14 centered mb-16">
              <div className="size-16-20 DarkBluePrimary font-weight-bold">
                <img src={FacebookIcon} className="w-h-20 mr-8" alt="fb icon" />
                Continue with Facebook
              </div>
            </div>
          )}
          cssClass="my-facebook-button-class transparent-button w-100-perc prl-0"
          isMobile={false}
        />
        <GoogleSignin
          callback={(profileObject) => this.checkForExistenceNCallback(
            profileObject,
            this.googleSignin,
            this.googleSignup,
          )}
          showError={(message) => {
            this.setState({ showErrorModal: true, error: message || defaultErrorMessage });
          }}
        />
      </div>
    );
  }

  orSeparator() {
    return (
      <div className="or-separator mb-24">
        <div className="gray-hr-line flex-1-1-45" />
        <div className="contentTertiary size-14-20 flex-1-1-10 font-weight-bold">OR</div>
        <div className="gray-hr-line flex-1-1-45" />
      </div>
    );
  }

  loginForm() {
    const { cart_id } = this.props;
    const {
      email, emailExists, isValidEmail, password,
    } = this.state;
    return (
      <>
        <div className="size-24-32 contentPrimary font-weight-bold mb-32">
          Login
          {' '}
          {cart_id ? ' or Signup to continue' : 'or Signup to See Availability'}
        </div>
        {/* {this.formSwitcher()} */}

        {cart_id ? null : (
          <>
            {this.otherLoginOptions()}
            {this.orSeparator()}
          </>
        )}

        <form onSubmit={(event) => {
          if (event && event.preventDefault) {
            event.preventDefault();
          }
          if (emailExists && email) {
            this.signin();
          } else if (isValidEmail) {
            this.emailCheck();
          }
        }}
        >
          <div className={`${isValidEmail ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Email</label>
            <EmailInput
              email={email}
              className="form-control generic-input-style size-16-24 contentPrimary"
              onEmailChange={({ email, isValid }) => this.setState({
                email, isValidEmail: isValid, emailExists: false, password: '', userLockedOut: false,
              })}
            />
            <img className="input-checkmark" src={checkMark} alt="chckmrk" />
          </div>
          {emailExists && email ? (
            <>
              <div className="mt-24 input-with-addon">
                <label className="contentPrimary size-16-20 mb-8">Password</label>
                <input
                  type={this.state.showPass ? 'text' : 'password'}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  value={password}
                  onChange={(event) => this.setState({ password: event.target.value })}
                />
                {password ? (
                  <div className="input-addon-r">
                    <Cancel className="cursor-pointer w-h-20 contentPrimary mr-16" onClick={() => this.setState({ password: '' })} />
                    {this.state.showPass ? <VisibilityOff className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: false })} /> : <Visibility className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: true })} />}
                  </div>
                ) : null}
              </div>
              {this.state.userLockedOut ? <div className="systemRed mt-8 size-14-20">Too many login attempts. Your account has been locked. Please check your email.</div> : null}
              <CTAButton
                text="Login"
                additionalClass="w-100-perc"
                additionalWrapperClass="mt-36 p-0-imp br-top-none-imp sm-relative-imp "
                disabled={this.state.userLockedOut || !password || !email}
                type="submit"
                bottomSection={(
                  <div className="centered">
                    <button
                      type="button"
                      className="btn p-0 btn-link deco-none CactusFlowerPrimary font-weight-bold mt-24 size-18-24"
                      onClick={() => {
                        triggerResetPasswordEmail({ email }, () => {
                          seResetPasswordInitiate('Log In', get(this.props, 'client.loggedIn', false));
                          this.setState({ showEmailSent: true });
                          seResetPasswordEmailSent('Log In', get(this.props, 'client.loggedIn', false));
                        }, (err) => {
                          this.setState({ error: get(err, 'response.data.errors.0.message', defaultErrorMessage), showErrorModal: true });
                        });
                      }}
                    >
                      Forgot password?
                    </button>
                  </div>
                )}
              />
            </>
          )
            : isValidEmail
              ? (
                <CTAButton
                  text="Continue"
                  additionalClass="w-100-perc"
                  additionalWrapperClass="mt-36 p-0-imp br-top-none-imp sm-relative-imp "
                  action={this.emailCheck}
                />
              )
              : null}
        </form>
      </>
    );
  }

  finishSignupForm() {
    return (
      <>
        <div className="size-24-32 contentPrimary font-weight-bold mb-24">{this.state.signin && this.state.emailExists ? 'Confirm Account info' : 'Finish Signup'}</div>

        <form onSubmit={this.finishSSOSignup}>
          <div className="row">
            <div className="col-xs-12 col-sm-6 form-group">
              <label className="contentPrimary size-16-20 mb-8">First Name</label>
              <div className={`${this.state.firstName ? 'success' : ''}`}>
                <input
                  type="text"
                  value={this.state.firstName}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  style={{ marginBottom: '20px' }}
                  onChange={(event) => this.setState({ firstName: event.target.value })}
                  required
                />
                <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 form-group ">
              <label className="contentPrimary size-16-20 mb-8">Last Name</label>
              <div className={`${this.state.lastName ? 'success' : ''}`}>
                <input
                  type="text"
                  value={this.state.lastName}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  style={{ marginBottom: '20px' }}
                  onChange={(event) => this.setState({ lastName: event.target.value })}
                  required
                />
                <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
              </div>
            </div>
          </div>
          <div className="form-group tel-input mb-24 ">
            <label className="contentPrimary size-16-20 mb-8">Phone number</label>
            <div className={`${this.state.isValidNumber ? 'success' : ''}`}>
              <PhoneInput
                showLable={false}
                onPhoneNumberChange={this.handleInputChange}
                className={`intl-tel-input generic-input-style size-16-24 contentPrimary ${this.state.isValidNumber ? 'valid' : 'invalid'} ${this.state.phoneAlreadyExists || (!this.state.isValidNumber && this.state.mobileNumber) ? 'error-state' : ''}`}
                phone={this.state.mobileNumber}
              />
              <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
            </div>
            {this.state.isValidNumber || !this.state.mobileNumber ? null : <div className="small-red-message mt-8">This number is invalid.</div>}
            {this.state.phoneAlreadyExists ? (
              <div className="small-red-message mt-8">
                {' '}
                An account with this phone number already exists. Please contact support:
                <br />
                <a className="underline small-red-message" href="mailto:support@soothe.com?subject=Duplicate phone number verification">support@soothe.com</a>
              </div>
            ) : null}
            <div className="size-12-18 contentTertiary mt-8">By providing your mobile number to Soothe, you agree that Soothe and therapists may use that number to communicate with you via calls and text messages (including automatically dialed calls and texts) regarding your appointments and account.</div>
          </div>

          {this.phoneCommunicationOptin()}
          {this.marketingEmailOptin()}

          <CTAButton
            text={this.state.signin && this.state.emailExists ? 'Agree and Continue' : 'Agree and Sign Up'}
            additionalClass="w-100-perc"
            additionalWrapperClass="p-0-imp br-top-none-imp sm-relative-imp "
            type="submit"
            disabled={this.state.phoneAlreadyExists}
          />
        </form>
      </>
    );
  }

  checkPassRules() {
    const { password } = this.state;
    this.setState({ listOfPassMistakes: generalCheckPassRules(password) });
  }

  signupForm() {
    return (
      <>

        <div className="size-24-32 contentPrimary font-weight-bold mb-24">Signup</div>

        <form onSubmit={this.signup}>
          <div className={`form-group mb-8 ${this.state.isValidEmail ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Email</label>
            <EmailInput
              email={this.state.email}
              className="form-control generic-input-style size-16-24 contentPrimary"
              onEmailChange={({ email, isValid }) => this.setState({
                email,
                isValidEmail: isValid,
              })}
            />
            <img className="input-checkmark" src={checkMark} alt="chckmrk" />
          </div>
          <div className="size-14-20 contentTertiary mb-24">
            We'll email you booking confirmations and receipts
          </div>
          {this.formSwitcher()}
          <div className=" mb-24">
            <div className={`form-group input-with-addon ${this.state.password ? 'success' : ''}`}>
              <label className="contentPrimary size-16-20 mb-8">Password</label>
              <input
                type={this.state.showPass ? 'text' : 'password'}
                className={`form-control generic-input-style size-16-24 contentPrimary ${!isEmpty(this.state.listOfPassMistakes) ? 'error-state' : ''}`}
                value={this.state.password}
                onChange={(event) => this.setState({
                  password: event.target.value,
                  listOfPassMistakes: [],
                })}
                onBlur={this.checkPassRules}
              />
              {this.state.password ? (
                <div className="input-addon-r d-flex-only align-items-center">
                  <Cancel className="cursor-pointer w-h-20 contentPrimary mr-16" onClick={() => this.setState({ password: '' }, this.checkPassRules)} />
                  {this.state.showPass ? <VisibilityOff className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: false })} /> : <Visibility className="cursor-pointer w-h-20 contentPrimary" onClick={() => this.setState({ showPass: true })} />}
                </div>
              ) : null}
              {isEmpty(this.state.listOfPassMistakes) && this.state.password ? <img src={checkMark} className="input-checkmark r-82" alt="chckmrk" /> : null}
            </div>
            {isEmpty(this.state.listOfPassMistakes) ? null : (
              <div className="systemRed mt-8 size-14-20">
                Your new password must contain the following:
                <ul>{map(this.state.listOfPassMistakes, (mistake, ky) => (<li key={`pass-rule-${ky}`}>{mistake}</li>))}</ul>
              </div>
            )}
          </div>
          <div className="form-group mb-24">
            <label className="contentPrimary size-16-20 mb-8">First Name</label>
            <div className={`${this.state.firstName ? 'success' : ''}`}>
              <input
                type="text"
                value={this.state.firstName}
                className="form-control generic-input-style size-16-24 contentPrimary"
                style={{ marginBottom: '20px' }}
                onChange={(event) => this.setState({ firstName: event.target.value })}
                required
              />
              <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
            </div>
          </div>

          <div className="form-group mb-24">
            <label className="contentPrimary size-16-20 mb-8">Last Name</label>
            <div className={`${this.state.lastName ? 'success' : ''}`}>
              <input
                type="text"
                value={this.state.lastName}
                className="form-control generic-input-style size-16-24 contentPrimary"
                style={{ marginBottom: '20px' }}
                onChange={(event) => this.setState({ lastName: event.target.value })}
                required
              />
              <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
            </div>
          </div>
          <div className="form-group tel-input mb-24 ">
            <label className="contentPrimary size-16-20 mb-8">Phone number</label>
            <div className={`${this.state.isValidNumber ? 'success' : ''}`}>
              <PhoneInput
                showLable={false}
                onPhoneNumberChange={this.handleInputChange}
                className={`intl-tel-input generic-input-style size-16-24 contentPrimary ${this.state.isValidNumber ? 'valid' : 'invalid'}  ${this.state.phoneAlreadyExists || (!this.state.isValidNumber && this.state.mobileNumber) ? 'error-state' : ''}`}
                phone={this.state.mobileNumber}
              />
              <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="chckmrk" />
            </div>
            {this.state.isValidNumber || !this.state.mobileNumber ? null : <div className="small-red-message mt-8">This number is invalid.</div>}
            {this.state.phoneAlreadyExists ? (
              <div className="small-red-message mt-8">
                {' '}
                An account with this phone number already exists. Please contact support:
                <br />
                <a className="underline small-red-message" href="mailto:support@soothe.com?subject=Duplicate phone number verification">support@soothe.com</a>
              </div>
            ) : null}
          </div>

          {this.phoneCommunicationOptin()}
          {this.marketingEmailOptin()}

          <CTAButton
            text="Agree and Sign Up"
            additionalClass="w-100-perc"
            additionalWrapperClass="p-0-imp br-top-none-imp sm-relative-imp"
            type="submit"
            disabled={this.state.phoneAlreadyExists
              || !isEmpty(this.state.listOfPassMistakes) || !this.state.password}
            bottomSection={(
              <div className="contentPrimary size-12-20 centered mt-24 mb-24">
                <div>
                  By selecting Agree and Sign Up, I agree to Soothe's
                  <a className="BluePrimary underline" href="/legal/terms/b2c/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                  {' '}
                  and
                  <a className="underline BluePrimary" href="/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
                </div>
              </div>
            )}
          />
        </form>
      </>
    );
  }

  resetPasswordLink() {
    return (
      <div className="contentPrimary size-12-20 centered mb-8 mt-20">
        Forgot your password?
        <a
          href="/users/password/new"
          target="_blank"
          rel="noopener noreferrer"
          className="BluePrimary underline"
        >
          Reset password
        </a>
      </div>
    );
  }

  renderErrorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const { showEmailSent, email, resetToken } = this.state;
    return (
      <>
        <div className="full-background">
          <div className="popup-frame-2 login-popup sm-br-rd-0">
            {this.props.closeModal ? (
              <div className="mb-24">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  <Close style={{ color: '#000000', fontSize: '24px' }} />
                </a>
              </div>
            ) : null}
            {this.renderSignupOrSignin()}
            <div id="persona-custom-parent" />
            {this.state.width < 800 ? (
              <NavbarRedesignForMarketplace jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")} />
            ) : null}
          </div>
        </div>

        {this.renderErrorModal()}
        {!loggedIn && (showEmailSent || resetToken)
          ? <ResetPasswordPopups showEmailSent={showEmailSent} email={email} /> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  fieldsHolder: state.fieldsHolder,
});

export default withLocalize(connect(mapStateToProps, { loadClient, setField })(GenericAuthModal));
