import React from 'react';
import { connect } from 'react-redux';
import { map, isEmpty, slice, get, find, size } from 'lodash';
import { setBookingFlowStep, setCart, setBookingFlowPreviousStep } from '../../../Actions';
import '../Assets/Styles/index.css';
import './Assets/Styles/ProviderPickStep.css';
import ProCard from './ProCard';
import { STEPS } from '../Shared/constants';
import { removePro, requestPro } from '../Shared/helpers';
import moment from 'moment-timezone';
import { decode } from '../Shared/encode';
import { HotJar, defaultErrorMessage, hasUuid, isGuestUser } from '../../../constants';
import { seBookingPAPView, seBookingPAPConfirm, sePickAProSelect } from '../../Shared/WebAnalytics';
import Slide from "@material-ui/core/Slide";

HotJar();

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            currentStepId: "TIMING"
        }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.closestMatch = this.closestMatch.bind(this);
        this.moreTopRatedProviders = this.moreTopRatedProviders.bind(this);
        this.listProCards = this.listProCards.bind(this);
        this.fillStateWithPros = this.fillStateWithPros.bind(this);
        this.listAllPros = this.listAllPros.bind(this);
        this.selectProAndNext = this.selectProAndNext.bind(this);
        this.removeProAndNext = this.removeProAndNext.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.skipButton = this.skipButton.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook) {
            this.props.changeBackground("");
            this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', "") })
            let previousStep = find(STEPS, (step) => (step.id === 'TIMING'));
            this.props.setBookingFlowPreviousStep(previousStep);
            this.props.toggleNavBarStyle(false);
        } else if (!isGuestUser()) {
            this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', "") })
            let previousStep = find(STEPS, (step) => (step.id === 'TIMING'));
            this.props.setBookingFlowPreviousStep(previousStep);
            this.props.toggleNavBarStyle(true);
            this.props.changeBackground('none');
        } else {
            this.setState({ currentStepId: get(this.props, 'bookingFlow.step.id', "") })
            let previousStep = find(STEPS, (step) => (step.id === 'TIMING'));
            this.props.setBookingFlowPreviousStep(previousStep);
            this.props.toggleNavBarStyle(!get(this.props,"fieldsHolder.listingPageStorage.bannerimage",""));
            this.props.changeBackground(get(this.props,"fieldsHolder.listingPageStorage.bannerimage","")||"none");
        }
        this.fillStateWithPros();
    }
    errorHandler(err) {
        let loader = this.props.setLoaderFlag;
        if(loader){
            loader(false);
        }
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
    }
    goToNextStep(nextStepId = "REVIEW") {
        let nextStep = find(STEPS, (step) => (step.id === nextStepId));
        this.props.setBookingFlowStepThroughParent(nextStep);
    }
    fillStateWithPros() {
        let cartId = get(this.props, "booking.cart.id", ""),
            cartProductId = get(this.props, "booking.cart.cartProducts.0.id", ""),
            dateUtc = get(this.props, "booking.cart.date.utc", ""),
            cart = get(this.props, "booking.cart", null),
            bkngDate = moment(get(cart, "time.utc", "")),
            nowDate = moment().tz(get(cart,"time.timezone", ""));
        if (cartId && cartProductId && dateUtc) {
                let providers = get(this.props, "bookingFlow.availableTherapists", []);
                if (isEmpty(providers)) {
                    let { currentStepId } = this.state, nextStepId = "REVIEW";
                    if (currentStepId === "REVIEW") {
                        nextStepId = "TIMING";
                    }
                    this.goToNextStep(nextStepId);
                } else {
                    this.setState({ providers });
                    seBookingPAPView(size(providers), cartId, "booking_23_control", "False",{
                        market: get(cart, "address.market", ""),
                        number_of_ga_pros: 0,
                        number_of_ic_pros: size(providers),
                        lead_time_selected: bkngDate.diff(nowDate, 'hours'),
                        is_today: bkngDate.isSame(nowDate, 'day'),
                        click_source: get(this.props, "bookingFlow.comingFromStep", "TIMING") === "TIMING" ? "Date_Screen" : "Review_Screen"
                    });
                }
        }
    }
    closestMatch() {
        let { providers } = this.state;
        return (<div className='mb-24'>
            <div className='medium-font size-24-32 txt-center'>Closest match</div>
            <div className='size-18-28 txt-center mb-24'>Deep tissue massage on Sep 5 between 8:00 pm - 9:00 pm with male or female provider</div>
            {this.listProCards(slice(providers, 0, 2))}
        </div>)

    }
    moreTopRatedProviders() {
        let { providers } = this.state;
        return (<div>
            <div className='medium-font size-24-32 txt-center mb-24'>More top rated providers</div>
            {this.listProCards(slice(providers, 2))}
        </div>)

    }
    selectProAndNext(proId, time, match_type, subIt, therapist) {
        let loader = this.props.setLoaderFlag;

        let cartId = get(this.props, "booking.cart.id", ""),
            cartProductId = get(this.props, "booking.cart.cartProducts.0.id", ""),
            formattedDate = moment(get(this.props, "booking.cart.date.utc", "")).format('YYYY-MM-DD'),
            cart_product = {
                "pro_ids": [proId],
                "session_date": formattedDate,
                "session_time": moment(`${formattedDate} ${time}`, "YYYY-MM-DD hh:mm A").format("HH:mm"),
                "remove_enhancements": true,
                "remove_therapist_preferences": true
            },
            cart = get(this.props, "booking.cart", null),
            bkngDate = moment(get(cart, "time.utc", "")),
            nowDate = moment().tz(get(cart,"time.timezone", ""));
        if (proId) {
            if(loader){
                loader(true);
            }
            requestPro(cartId, cartProductId, cart_product, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
                if(loader){
                    loader(false);
                }
                this.props.assignToCart({ cart: decode(response.data.cart) });
                sePickAProSelect({ ab_test_booking_flow: "existing_flow", market: get(this.props, "booking.cart.address.market", ""), list_position: subIt + 1, rating_value: get(therapist, "rating", 0), bookings_count: get(therapist, "number_of_bookings", 0), is_ic: true })
                seBookingPAPConfirm(proId || "", subIt||0, Boolean(match_type === "exact"), cartId, {
                    ab_test_booking_flow: "existing_flow",
                    market: get(this.props, "booking.cart.address.market", ""),
                    is_ic: true,
                    number_of_ga_pros: 0,
                    number_of_ga_pros_selected: 0,
                    lead_time_selected: bkngDate.diff(nowDate, 'hours'),
                    is_today: bkngDate.isSame(nowDate, 'day'),
                    click_source: get(this.props, "bookingFlow.comingFromStep", "TIMING") === "TIMING" ? "Date_Screen" : "Review_Screen"
                  });
                this.goToNextStep();
            }, this.errorHandler)
        }
    }
    removeProAndNext() {
        let cartId = get(this.props, "booking.cart.id", ""),
            cartProductId = get(this.props, "booking.cart.cartProducts.0.id", "");
        removePro(cartId, cartProductId, get(this.props,"fieldsHolder.csrfToken",""), (response) => {
            this.props.assignToCart({ cart: decode(response.data.cart) });
            this.goToNextStep();
        }, this.errorHandler)
    }
    skipButton() {
        return (<div className='skip-section-style'>
            <div className='size-18-24 color-black medium-font'>Struggling to decide?</div>
            <div className='size-14-20 color-gray mt-12'>Let us find a Provider for you by sending a request out to our larger network. This may take longer to get confirmed and is not always guaranteed.</div>
            <button className='btn skip-btn-style mt-12' onClick={this.removeProAndNext}>Send request to all</button>
        </div>)
    }
    listAllPros() {
        let { providers } = this.state, guest=Boolean(hasUuid() && get(this.props,"fieldsHolder.listingPageStorage.bannerimage",""));
        if (!isEmpty(providers)) {
            return (<>
                <div className={`medium-font size-44-52 txt-center ${guest ? 'color-white txt-shadow sm-color-black' : 'color-black'} mb-48`}>Pick a Provider</div>
                <div className={`row ${guest? 'background-primary p-24 border-radius-8':''} `}>
                    {map(providers, (pro, subIt) => (
                        <div key={`therapist-${subIt}`} className={`col-xs-12 mb-24 ${!guest? "col-sm-4":"col-sm-6 "}`}>
                            <ProCard therapist={pro} time={pro.time || ""} selectPro={(proId, time, match_type)=>this.selectProAndNext(proId, time, match_type, subIt, pro)}
                                errorHandler={this.errorHandler} />
                        </div>
                    ))}
                    <div className={`col-xs-12 mb-24 ${!guest?"col-sm-4":"col-sm-12"}`}>
                        {this.skipButton()}
                    </div>
                </div>
            </>);
        }
        return null;
    }
    listProCards(arr) {
        if (!isEmpty(arr)) {
            return (<div className='row'>
                {map(arr, (timeObj, it) => (
                    map(timeObj.therapists, (therap, subIt) => (
                        <div key={`therapist-${it}-${subIt}`} className='col-xs-4 mb-24'>
                            <ProCard therapist={therap} time={timeObj.time} selectPro={this.goToNextStep} />
                        </div>
                    ))

                ))}
            </div>);
        }
        return null;
    }
    render() {
        let guest=Boolean(hasUuid() && get(this.props,"fieldsHolder.listingPageStorage.bannerimage",""));
        return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <div className='display-flex'>
            <div className={guest?'max-w-50-vw':'max-width-75'}>
                <div className=''>
                    {this.listAllPros()}
                </div>
            </div>
        </div>
        </Slide>)
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking.product,
    fieldsHolder: state.fieldsHolder
});

export default connect(mapStateToProps, { setBookingFlowStep, setCart, setBookingFlowPreviousStep })(Index);